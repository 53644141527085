import React from "react";
import { useLittera } from "@assembless/react-littera";

export const DashboardTranslate = {
    ObjectNotFound:{
        en_US: "Object not found",
        ru_RU: "Объекты еще не добавлены"
    },
    Unknow:{
        en_US: "Unknow",
        ru_RU: "Не указано"
    },
    StationOnTheMap:{
        en_US: "Stations on the map",
        ru_RU: "Размещение объектов на карте"
    },
    User:{
        en_US: "User",
        ru_RU: "Пользователь"
    },
    FillingDate:{
        en_US: "Filling date",
        ru_RU: "Дата налива"
    },
    Fuel:{
        en_US: "Fuel",
        ru_RU: "Топливо"
    },
    Liters:{
        en_US: "Liters",
        ru_RU: "Литры"
    },
    Controller:{
        en_US: "Controller",
        ru_RU: "Контроллер"
    },
    Controllers:{
        en_US: "Controllers",
        ru_RU: "Контроллеры"
    },
    More:{
        en_US: "More",
        ru_RU: "Подробнее"
    },
    Actions:{
        en_US: "Actions",
        ru_RU: "Действия"
    },
    LastFillings:{
        en_US: "Last fillings today",
        ru_RU: "Последние заправки"
    },
    OpenTheMap:{
        en_US: "Open the map",
        ru_RU: "Показать на карте"
    },
    Fuel:{
        en_US: "Fuel",
        ru_RU: "Топливо"
    },
    Volume:{
        en_US: "Volume",
        ru_RU: "Объем"
    },
    CurrentVolume:{
        en_US: "Current volume",
        ru_RU: "Текущий объем"
    },
    Occupancy:{
        en_US: "Occupancy",
        ru_RU: "Заполненность"
    },
    OnConnect:{
        en_US: "Connect",
        ru_RU: "На связи"
    },
    Disconnect:{
        en_US: "Disconnect",
        ru_RU: "Без связи"
    },
    ControllerConnectMessage:{
        en_US: "Information on controllers about the availability of communication and a valid license",
        ru_RU: "Информация по контроллерам о наличии связи и действующей лицензии"
    },
    LicenseActive:{
        en_US: "Active",
        ru_RU: "Действует"
    },
    LicenseDisable:{
        en_US: "Disable",
        ru_RU: "Отключена"
    },
    FuelRelease:{
        en_US: "Fuel release",
        ru_RU: "Отпуск топлива"
    },
    UserAndTransport:{
        en_US: "Users and transports",
        ru_RU: "Пользователи и транспорт"
    },
    Users:{
        en_US: "Users",
        ru_RU: "Пользователи"
    },
    Transports:{
        en_US: "Transports",
        ru_RU: "Транспорт"
    },
    Day:{
        en_US: "Day",
        ru_RU: "День"
    },
    Month:{
        en_US: "Month",
        ru_RU: "Месяц"
    },
    Companies:{
        en_US: "Companies",
        ru_RU: "Компании"
    },
    CompanyActive:{
        en_US: "Active",
        ru_RU: "Активные"
    },
    CompanyBlocked:{
        en_US: "Blocked",
        ru_RU: "Заблокированы"
    },
    FuelIntake:{
        en_US: "Fuel intake",
        ru_RU: "Поступление топлива"
    }
};
