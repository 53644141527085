import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Offcanvas, Table, Spinner, Card, Dropdown, Modal, DropdownButton, Tooltip, OverlayTrigger, Nav} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import moment from 'moment/moment';
import api from '../../../utils/axiosInterceptions';

export const LevelMeterPeriod = ({controllerInfo, periodLevelMeterRead, openPeriod, setOpenPeriod, translated, translatedForm}) => {

    const params = useParams()
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [loadingBtn, setLoadingBtn] = useState(false)

    const listPeriodTime = [
        {label:translated.Disable, value:0},
        {label:"5", value:5},
        {label:"10", value:10},
        {label:"15", value:15},
        {label:"30", value:30},
        {label:"60", value:60}
    ]

    const getLevelMeterPeriod = () => {
        api.get("/api/controllers/details/period-level-meter", {
            headers:{
                "content-type" : "application/json",
                "Id": controllerInfo.id
            },
            params:{
                Id: controllerInfo.id,
            },
            withCredentials:true
        })
        .then(function (response) {
            let periodValue = listPeriodTime.filter((item) => item.value == response.data.dataResult)
            setValue("PeriodLevelMeterRead", periodValue[0])
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    
    const onSubmit = data => {
        console.log(data)
        setLoadingBtn(true)
        api.post('/api/controllers/details/update-period-level-meter', {
            Id: controllerInfo.id,
            PeriodLevelMeterRead: data.PeriodLevelMeterRead.value,
            withCredentials:true
        })
        .then(function (response) {
            console.log("critical level")
            
            setLoadingBtn(false)
            displayToast(response.data.message, response.data.isSuccess)
            let periodValue = listPeriodTime.filter((item) => item.value == response.data.dataResult)
            setValue("PeriodLevelMeterRead", periodValue[0])
        })
        .catch(function (error) {
            console.log(error);
            setLoadingBtn(false)
        });
    }

    const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    
        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
    
    }

    useEffect(() => {
        getLevelMeterPeriod()
    }, [])

    return (
        <Offcanvas placement="end" show={openPeriod} onHide={() => setOpenPeriod(false)}>
            <Offcanvas.Header closeButton>
            <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
                {translated.PeriodSaveLevelMeterData}
            </Offcanvas.Title>
            </Offcanvas.Header>
            <div className="dropdown-divider"></div>
            <Offcanvas.Body>  
                
                <Form onSubmit={handleSubmit(onSubmit)}>

                <Form.Group className="mb-3">
                <Form.Label className="text-black">{translated.Period} <sup className="text-danger">*</sup></Form.Label>
                
                    <Controller
                        name="PeriodLevelMeterRead"                                
                        control={control}
                        rules={{ required: translatedForm.Required}}
                        render={({ field:{value} }) => (
                        <Select
                            value={value}
                            options={listPeriodTime}                        
                            placeholder={translatedForm.SelectFromList}
                            classNamePrefix="select"
                            onChange={(item) => {
                                setValue("PeriodLevelMeterRead", item)
                            }}
                        />
                        )}
                    />
                    {errors.PeriodLevelMeterRead && 
                        <span className="text-danger font-size-13">{errors.PeriodLevelMeterRead.message}</span>
                    }
                </Form.Group>

                <div className="form-group mt-3">
                    <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                        {loadingBtn && (
                            <Spinner animation="border" variant="light" 
                                as="span"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                        {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                    </button>
                </div>
            
            </Form>
            
            </Offcanvas.Body>
        </Offcanvas>
    )
}