import Dashboard from "../components/Dashboard/Dashboard";
import Company from "../components/Company/Company";
import CompanySingle from "../components/Company/CompanySingle";
import Login from '../components/Login/Login';
import Transport from '../components/Transport';
import TransportSingle from '../components/Transport/single';
import { Users } from "../components/User";
import { UserSingle } from "../components/User/single";
import { Fuel } from "../components/Catalog/Fuel";
import { TypeLimit } from "../components/Catalog/TypeLimit";
import { TypeAuth } from "../components/Catalog/TypeAuth";
import { Stations } from "../components/Station";
import { StationSingle } from "../components/Station/single";
import { Controllers } from "../components/Controller";
import ControllerSingle from "../components/Controller/single";
import Roles from "../components/Role";
import { RoleSingle } from "../components/Role/single";
import { Pouring } from "../components/Pouring";
import { PouringControllers } from "../components/Pouring/controllers";
import { ReportLevelMeter } from "../components/Report/reportLevelMeter";
import { ReportAllMessages } from "../components/Report/reportAllMessages";
import { ReportFillings } from "../components/Report/reportFillings";
import { ReportHatchOpenClose } from "../components/Report/reportHatchOpenClose";
import { ReportChangePrice } from "../components/Report/reportChangePrice";
import { ReportActions } from "../components/Report/reportActions";
import { ReportPrices } from "../components/Report/reportPrices";
import { ReportGsmController } from "../components/Report/reportGsmController";
import { ReportGsmUser } from "../components/Report/reportGsmUser";
import { ReportGsmNotGrouping } from "../components/Report/reportGsmNotGrouping";
import { ReportSumGSMController } from "../components/Report/reportSumGsmController";
import { ReportSumGSMUser } from "../components/Report/reportGsmSumUser";
import { ReportGsmConnect } from "../components/Report/reportGsmConnect";
import { ReportGsmSumTransport } from "../components/Report/reportGsmSumTransport";
import { ReportGsmSumCompany } from "../components/Report/reportGsmSumCompany";
import { ReportGsmPouringStation } from "../components/Report/reportGsmPouringStation";
import { ReportGsmPouringController } from "../components/Report/reportGsmPouringController";
import { Notifications } from "../components/Notification";
import { ReportGsmByMass } from "../components/Report/reportGsmByMass";
import { ReportFuelIntake } from "../components/Report/reportFuelIntake";
import { ReportMaterialBalance } from "../components/Report/reportMaterialBalance";

export const privateRoutes = [
    {path: '/dashboard', component: Dashboard, exact: true},

    {path: '/companies', component: Company, exact: true},
    {path: '/company/:id', component: CompanySingle, exact: true},

    {path: '/transports', component: Transport, exact: true},
    {path: '/transport/:id', component: TransportSingle, exact: true},

    {path: '/stations', component: Stations, exact: true},
    {path: '/station/:id', component: StationSingle, exact: true},

    {path: '/users', component: Users, exact: true},
    {path: '/user/:id', component: UserSingle, exact: true},

    {path: '/controllers', component: Controllers, exact: true},
    {path: '/controller/:id', component: ControllerSingle, exact: true},

    {path: '/fuels', component: Fuel, exact: true},
    {path: '/type-limits', component: TypeLimit, exact: true},
    {path: '/type-auths', component: TypeAuth, exact: true},

    {path: '/pourings', component: Pouring, exact: true},
    {path: '/pouring-controllers', component: PouringControllers, exact: true},

    {path: '/roles', component: Roles, exact: true},
    {path: '/role/:id', component: RoleSingle, exact: true},

    {path: '/notifications', component: Notifications, exact: true},

    {path: '/reports/level-meter', component: ReportLevelMeter, exact: true},
    {path: '/reports/messages', component: ReportAllMessages, exact: true},
    {path: '/reports/fillings', component: ReportFillings, exact: true},
    {path: '/reports/event-hatch-open-close', component: ReportHatchOpenClose, exact: true},
    {path: '/reports/change-prices', component: ReportChangePrice, exact: true},
    {path: '/reports/actions', component: ReportActions, exact: true},
    {path: '/reports/prices', component: ReportPrices, exact: true},
    {path: '/reports/gsm-controllers', component: ReportGsmController, exact: true},
    {path: '/reports/gsm-users', component: ReportGsmUser, exact: true},
    {path: '/reports/gsm-mass', component: ReportGsmByMass, exact: true},
    {path: '/reports/gsm-not-grouping', component: ReportGsmNotGrouping, exact: true},
    {path: '/reports/gsm-sum-controllers', component: ReportSumGSMController, exact: true},
    {path: '/reports/gsm-sum-users', component: ReportSumGSMUser, exact: true},
    {path: '/reports/gsm-sum-transports', component: ReportGsmSumTransport, exact: true},
    {path: '/reports/gsm-sum-companies', component: ReportGsmSumCompany, exact: true},
    {path: '/reports/gsm-connects', component: ReportGsmConnect, exact: true},
    {path: '/reports/gsm-pouring-stations', component: ReportGsmPouringStation, exact: true},
    {path: '/reports/gsm-pouring-controllers', component: ReportGsmPouringController, exact: true},
    {path: '/reports/fuel-intake', component: ReportFuelIntake, exact: true},
    {path: '/reports/material-balance', component: ReportMaterialBalance, exact: true},
]

export const publicRoutes = [
    {path: '/login', component: Login, exact: true},
]