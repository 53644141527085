import React, { useState, useEffect, useMemo, useContext, Redirect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Offcanvas, Form, Card, Spinner, Alert, ProgressBar} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller} from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';
import Chart from "react-apexcharts";
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import './../../boxicons.css';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { Russian } from "flatpickr/dist/l10n/ru.js"
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateReport } from '../../lang/reports/translateReport';
import {TranslatePouring} from '../../lang/pourings/translatePouring';
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';
import { Chart as ChartJS } from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom';
import {
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2'
import api from '../../utils/axiosInterceptions';

export const ReportLevelMeter = () => {

  const router = useHistory();
  const {isAuth} = useContext(AuthContext);
  const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
  const [showFormAdd, setShowFormAdd] = useState(false)
  const [listStation, setListStation] = useState([])
  const [listCompanies, setListCompanies] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [listControllers, setListControllers] = useState([])
  const [listData, setListData] = useState([])
  const [countStations, setCountStations] = useState(0)
  const translated = useLittera(TranslateReport);
  const translatedPouring = useLittera(TranslatePouring);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();
  const [showDiagramm, setShowDiagramm] = useState(false)
  const [diagrammColumn, setDiagrammColumn] = useState({
    series:{
        labels: [],
        datasets: [],
    }
  })
  const [permission, setPermission] = useState({
    read:false,
    create:false,
    fullAccess:false
  })
  const [selectedCompany, setSelectedCompany] = useState({
    label:'',
    value:0
  })
  const [selectedStation, setSelectedStation] = useState({
    label:'',
    value:0
  })
  const [loadingData, setLoadingData] = useState({
    grid:false,
    button:false
  });
  
  const displayToast = (message, status, typeMessage) => {
    
    if(typeMessage == "success") {
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    } else {
        toast.info(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    }
  }

  const columns = useMemo(
    () => [
    {
        accessorFn: (row) => moment(row.dateMeasure).format("DD.MM.YYYY HH:mm"),
        accessorKey: 'date', //access nested data with dot notation
        header: translatedPouring.Date,
    },
    {
        accessorFn: (row) => row.indexOnController,
        accessorKey: 'levelMeterId',
        header: translatedPouring.LevelMeter,
    },
    {
        accessorFn: (row) => row.level,
        accessorKey: 'level',
        size:100,
        header: translatedPouring.Level,
    },
    {
        accessorFn: (row) => row.temperature,
        accessorKey: 'temperature',
        size:100,
        header: translatedPouring.Temperature,
    },
    {
        accessorFn: (row) => row.fill,
        accessorKey: 'fill',
        size:100,
        header: translatedPouring.Occupancy,
    },
    {
        accessorFn: (row) => row.allVolume,
        accessorKey: 'allVolume',
        size:100,
        header: translatedPouring.Volume,
    },
    {
        accessorFn: (row) => row.mass,
        accessorKey: 'mass',
        size:100,
        header: translatedPouring.Mass,
    },
    {
        accessorFn: (row) => row.density,
        accessorKey: 'density',
        size:100,
        header: translatedPouring.Density,
    },
    ],
    [],
  );

  const getCompanies = () => {
    api.get('/api/company/all', {
        withCredentials:true
    })
    .then(function (response) {
        console.log("companies")
        console.log(response.data.listDatas)
        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))

        setListCompanies(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getStations = companyId => {
    api.get('/api/company/details/controllers', {
        headers:{
            "content-type" : "application/json",
            "companyId":companyId
        },
        params:{
            companyId: companyId,
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("stations of company")
        

        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))
        setListControllers(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getControllers = stationId => {
    api.get('/api/stations/details/controllers', {
        headers:{
            "content-type" : "application/json",
            "Id":stationId
        },
        params:{
            Id: stationId,
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("controllers of station")
        

        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))
        setListControllers(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const onSubmit = data => {
    console.log(data)
    setLoadingData({button:true})
    api.get("/api/reports/level-meters", {
        headers:{
            "content-type" : "application/json",
            "ControllerId": data.ControllerId.id,
            "EndPeriod": data.EndPeriod.toISOString(),
            "StartPeriod": data.StartPeriod.toISOString()
        },
        params:{
            ControllerId: data.ControllerId.id,
            EndPeriod: data.EndPeriod.toISOString(),
            StartPeriod: data.StartPeriod.toISOString()
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("report level meters")
        console.log(response.data.dataResult)
        setIsLoading(true)
        setPermission({
            read: response.data.read,
            fullAccess: response.data.fullAccess
        })
        setLoadingData({button:false})
        
        if(response.data.read)
        {
            setListData(response.data.dataResult.levelMeterJournal)
            displayToast(response.data.message, response.data.isSuccess, response.data.typeMessage)

            let array_labels = []
            let array_series = []
            let array_datataset = []
            let backgroundColor = [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'rgb(100, 57, 0)',
            ]

            /*response.data.dataResult.chartDateLabels.map((item) => {
                array_labels.push(moment(item).format("DD.MM.YYYY HH:mm")) 
            })*/

            response.data.dataResult.levelMeterResults.map((item, index) => {
                console.log(item.data)
                let array_item_series = []
                item.data.map((i) => {
                    array_item_series.push({
                        x: i.dateMeasure,
                        y: i.allVolume
                    })
                    //array_labels.push(moment(i.dateMeasure).format("DD.MM.YYYY HH:mm:ss"))                    
                })

                let color = backgroundColor[index]

                array_datataset.push({
                    label:++index,
                    borderColor: color,
                    backgroundColor: color,
                    data: array_item_series, 
                    tension: 0.1,
                    fill: false,
                })
            })

            console.log("dataset")
            console.log(array_datataset)
            console.log("array label")
            console.log(array_labels)
            
            setDiagrammColumn({
                series: {
                    labels: array_labels,
                    datasets: array_datataset,
                }
            })
        }
    })
    .catch(function (error) {
        console.log(error);
        setIsLoading(true)
        setLoadingData({button:false})
    });
  }

  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    getCompanies()
    setValue("StartPeriod", new Date())
    setValue("EndPeriod", new Date())
  }, []);

  const options = {
    responsive: true,
    animation:false,
    parsing:false,
    plugins: {
        colors: {
            forceOverride: true
        },
        legend: {
            position: 'top',
        },
    },
    scales: {
        x: {
           ticks: {
               display: false //this will remove only the label
          }
       }
    },
    parsing: {
        xAxisKey: 'x',
        yAxisKey: 'y'
    }
  };


  return (
    isAuth ?
    <>
    <div className="d-sm-flex align-items-center justify-content-between">
        <h2 className="mb-sm-0 fw-semibold  font-size-18 benzamat-font-bold text-black">
            {translated.Analytics} - <span className="text-secondary benzamat-font-bold font-size-18">
                {translated.ReadingLevelMeter}
            </span>
        </h2>
        <div className="page-title-right">
            <Button varian="primary" onClick={() => setShowFormAdd(true)}>
                {translated.FilterReport}
            </Button>
        </div>            
    </div>
    <div className="row mt-4">

        {
            isLoading
            ?
            permission.read || permission.fullAccess
            ?
            <>
                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Line options={options} data={diagrammColumn.series} />
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12 mt-3">         
                    <div className="mt-2">
                        <MaterialReactTable 
                            columns={columns} 
                            data={listData}
                            localization={MRT_Localization_EN}
                            initialState={{ showColumnFilters: true }}
                            enableTopToolbar={false}
                            enableRowVirtualization
                            muiTablePaperProps={{
                            elevation: 1,
                            }}
                            muiTableHeadCellProps={{
                            //simple styling with the `sx` prop, works just like a style prop in this example
                            sx: {
                                fontWeight: 'bold',
                                color:"#000",
                                backgroundColor:'#F3F3F3',
                                fontFamily: 'BenzamatRegular',
                                zIndex:5
                            },
                            }}
                            muiTableBodyCellProps={{
                            sx: {
                                color:"#000",
                                fontFamily: 'BenzamatRegular',
                                zIndex:5
                            },
                            }}
                        />
                    </div>
                </div>            
            </>
            :
            <div className="col-xs-12 col-lg-12 col-md-12">
                <Alert variant='danger'>
                    {translated.PermissionDenied}
                </Alert>
            </div>
            : 
            <div className="col-xs-12 col-lg-12 col-md-12">
                <Alert variant='info'>{translated.DataNotLoad}</Alert>
            </div>
        }
    </div>

    <Offcanvas placement="end" show={showFormAdd} onHide={() => setShowFormAdd(false)}>
        <Offcanvas.Header closeButton>
        <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
            {translated.FilterReport}
        </Offcanvas.Title>
        </Offcanvas.Header>
        <div className="dropdown-divider"></div>
        <Offcanvas.Body>
        
        <form onSubmit={handleSubmit(onSubmit)} >
            <div className="row">
                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.Company}</Form.Label>
                            <Controller
                                name="Station"                                
                                control={control}
                                render={({ field }) => (
                                <Select
                                    options={listCompanies}                   
                                    isSearchable                  
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                    onChange={(item) => {
                                        getStations(item.id)
                                        setSelectedCompany({label:item.label, value:item.value})
                                        setValue("ControllerId", null)
                                    }}
                                />
                                )}
                            />
                        {errors.Station && 
                            <span className="text-danger font-size-13">{errors.Station.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.Controller}</Form.Label>
                            <Controller
                                name="ControllerId"                                
                                control={control}
                                rules={{required: translatedForm.Required}}
                                render={({ field: {value} }) => (
                                <Select
                                    options={listControllers}                   
                                    isSearchable                  
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                    value={value}
                                    onChange={(item) => {
                                        console.log(item)
                                        setValue("ControllerId", item)
                                    }}
                                />
                                )}
                            />
                        {errors.ControllerId && 
                            <span className="text-danger font-size-13">{errors.ControllerId.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.StartDate}</Form.Label>
                        <Controller
                            name="StartPeriod"                                
                            control={control}
                            rules={{required: translatedForm.Required}}
                            render={({ field:{value} }) => (
                            <Flatpickr
                                className='form-control'
                                options={{ 
                                    time_24hr: true,  
                                    enableTime: true,
                                    dateFormat: "d.m.Y",
                                    locale: Russian
                                }}
                                value={value}
                                placeholder={translatedForm.SelectFromList}
                                onChange={([date]) => {
                                    setValue("StartPeriod", date)
                                }}
                            />
                            )}
                        />
                        {errors.StartPeriod && 
                            <span className="text-danger font-size-13">{errors.StartPeriod.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.EndDate}</Form.Label>
                        <Controller
                            name="EndPeriod"                                
                            control={control}
                            rules={{required:translatedForm.Required}}
                            render={({ field:{value} }) => (
                            <Flatpickr
                                className='form-control'
                                options={{ 
                                    time_24hr: true,  
                                    enableTime: true,
                                    dateFormat: "d.m.Y",
                                    locale: Russian
                                }}
                                placeholder={translatedForm.SelectFromList}
                                value={value}
                                onChange={([date]) => {
                                    setValue("EndPeriod", date)
                                }}
                            />
                            )}
                        />
                        {errors.EndPeriod && 
                            <span className="text-danger font-size-13">{errors.EndPeriod.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <button type="submit" disabled={loadingData.button} className="btn mt-2 btn-primary">
                        {loadingData.button && (
                            <Spinner animation="border" variant="light" 
                                as="span"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        {loadingData.button && <span>{translated.GenerateReport}</span>}
                        {!loadingData.button && <span>{translated.GenerateReport}</span>}
                    </button>
                </div>

            </div>
        </form>

        </Offcanvas.Body>
    </Offcanvas>

    </>
    :
    <Redirect to='/login'/>
  );

}
