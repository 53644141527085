import React, { useState, useEffect, useContext, useMemo, Redirect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Offcanvas, Modal, Form, Spinner, DropdownButton, Dropdown, Badge, Alert} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller} from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { Russian } from "flatpickr/dist/l10n/ru.js"
import { UploadUsers } from './upload';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateUser } from '../../lang/users/user';
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';
import api from '../../utils/axiosInterceptions';
import InputMask from 'react-input-mask';

export const Users = () => {

  const router = useHistory();
  const {isAuth} = useContext(AuthContext);
  const { control, register, handleSubmit, setValue, reset, formState, getValues, formState: { errors }} = useForm();
  const [countUsers, setCountUsers] = useState(0)
  const [listUsers, setListUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [uploadFormShow, setUploadFormShow] = useState(false)
  const [showDataAuthForm, setShowDataAuthForm] = useState(false)
  const [currentAuthRoleId, setCurrentAuthRole] = useState(0)
  const [showDeleteUser, setShowDeleteUser] = useState(false)
  const [userIdForDelete, setUserIdForDelete] = useState(0)
  const translated = useLittera(TranslateUser);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();
  const [selectedBenzaRfidType, setSelectedBenzaRfidType] = useState({
    label:"Benza Mifare",
    value:true
  })
  const [selectedCompany, setSelectedCompany] = useState({
    label:translatedForm.SelectFromList,
    value:0
  })
  const [selectedTypeLimit, setSelectedTypeLimit] = useState(0)
  const [listTypeLimit, setListTypeLimit] = useState({
    label:'',
    value:0
  })
  const [limitDay, setLimitDay] = useState(0)
  const [limitMonth, setLimitMonth] = useState(0)
  const [listCompanies, setListCompanies] = useState([])
  const [listControllers, setListControllers] = useState([])
  const [listTypeAuth, setListTypeAuth] = useState([])
  const [listRoles, setListRoles] = useState([])
  const [showFormAdd, setShowFormAdd] = useState(false);
  const [roleIsDriver, setRoleIsDriver] = useState(false)
  const [rfidBtnLoad, setRfidBtnLoad] = useState(false)
  const [permission, setPermission] = useState({
    read: false,
    create: false,
    fullAccess: false
  })
  const [loadingData, setLoadingData] = useState({
    button:false,
    grid:false
  })
  const [selectedTypeAuth, setSelectedTypeAuth] = useState({
    rfid:false,
    password:false,
    rfid_password:false,
  })
  const [selectedAuth, setSelectedAuth] = useState({
    label:translatedForm.SelectFromList,
    value:0
  })
  const listExistDataAuth = [
    {label:translated.Yes, value:true},
    {label:translated.No, value:false}
  ]
  const listBenzaRfidType = [
    {label:"Benza Mifare", value:true},
    {label:"Benza EM Marine", value:false}
  ]
  const listAuthAuto = [
    {label:translated.Yes, value:1},
    {label:translated.No, value:0}
  ]

  const formatChars = {
    '*': '[A-Fa-f0-9]'
  };

  const handleShow = () => {
    setSelectedBenzaRfidType({
      label:listBenzaRfidType[0].label,
      value:listBenzaRfidType[0].value
    })
    getCompanies()
    getRoles()
    getTypeAuth()
    setShowFormAdd(true);
  }
  const handleClose = () => setShowFormAdd(false);

  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    getUsers()
    getEnumTypeLimits()
  }, [])

  const showFormAuth = data => {
    setShowDataAuthForm(data.value)
  }

  const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    
        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
  }

  const deleteUser = () => {
    api.post('/api/users/details/delete', {
      Id:userIdForDelete,
      withCredentials: true
    })
    .then(function (response) {
      displayToast(response.data.message, response.data.isSuccess)
      setListUsers(response.data.listDatas)
      setCountUsers(response.data.listDatas.length)
    })
    .catch(function (error) {
      console.log(error);
      displayToast(error.response.data.message, error.response.data.isSuccess)
    });
  }

  const onDelete = (id) => {
    setShowDeleteUser(true)
    setUserIdForDelete(id)
  }

  const getLastCard = () => {
    setRfidBtnLoad(true)
    api.get("/api/users/details/cards/last", {
      withCredentials:true
    })
    .then(function (response) {
      
      setValue("NumberRfid", response.data.dataResult)
      setRfidBtnLoad(false)
    })
    .catch(function (error) {
      console.log(error);
      setRfidBtnLoad(false)
    }); 
  }

  const handleCheckLimits = () => {
    console.log(`month = ${limitMonth}`)
    console.log(`day = ${limitDay}`)
    if(Number(limitMonth) < Number(limitDay)) {
      displayToast(translated.WarningLimitMonth, false)
      setLoadingData({button:true})
    } else {
      setLoadingData({button:false})
      return true
    }
    return false
  }

  const getEnumTypeLimits = () => {
    api.get("/api/enum-type-limits/all", {
    })
    .then(function (response) {
        const addTypeLimit = response.data.listDatas.filter((i) => i.id != 1 && i.id != 2).map(item => ({
            ...item,
            value: item.id,
            label: item.name
        }))
        setListTypeLimit(addTypeLimit)
    })
    .catch(function (error) {
        console.log(error);
    });    
  }

  const getStationsCompany = id => {
    api.get('/api/company/details/controllers', {
      headers:{
        "content-type" : "application/json",
        "companyId":id
      },
      params:{
        companyId: id,
      },
      withCredentials:true
    })
    .then(function (response) {

      if(response.data.listDatas.length == 0)
      {
       // displayToast("Контроллеры еще не добавлены к станциям, которые относятся к выбранной компании", false)
      }
      else
      {
        const add = response.data.listDatas.map(item => ({
          ...item,
          value: item.id,
          label: item.connectId
        }))
        setListControllers(add)
        //displayToast("Список контроллеров компании успешно получен", response.data.isSuccess)
      }
    })
    .catch(function (error) {
        console.log(error);
        setLoadingData({grid:false})
    });
  }

  const getCompanies = () => {
    api.get('/api/company/all', {
        withCredentials:true
    })
    .then(function (response) {
        const addCompany = response.data.listDatas.map(item => ({
            ...item,
            value: item.id,
            label: item.name
        }))
        setListCompanies(addCompany)
    })
    .catch(function (error) {
        console.log(error);
        setLoadingData({grid:false})
    });
  }

  const getUsers = () => {
    setLoadingData({grid:true})
    setIsLoading(true)
    api.get('/api/users/all', {
        withCredentials:true
    })
    .then(function (response) {
      console.log("users")
      
      setPermission({
        read: response.data.read,
        create: response.data.create,
        fullAccess: response.data.fullAccess
      })
      setListUsers(response.data.listDatas)
      setLoadingData({grid:false})
      setCountUsers(response.data.listDatas.length)
      setIsLoading(false)
      setCurrentAuthRole(response.data.currentAuthUserRoleId)
    })
    .catch(function (error) {
        console.log(error);
        setLoadingData({grid:false})
    });
  }

  const getRoles = () => {
    api.get('/api/roles/all', {
        withCredentials:true
    })
    .then(function (response) {


      if(currentAuthRoleId == 1) {
        const add = response.data.listDatas.map(item => ({
            ...item,
            value: item.id,
            label: item.name
        }))
        setListRoles(add)
      } else {
        const add = response.data.listDatas.filter((a) => a.id != 1).map(item => ({
            ...item,
            value: item.id,
            label: item.name
        }))
        setListRoles(add)
      }
    })
    .catch(function (error) {
        console.log(error);
    });
  }
  
  const getTypeAuth = () => {
    api.get('/api/type-auths/all', {
        withCredentials:true
    })
    .then(function (response) {
        const add = response.data.listDatas.filter(i => i.id != 2).map(item => ({
            ...item,
            value: item.id,
            label: item.name
        }))
        setListTypeAuth(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const onSubmit = data => {
    let check_limit = handleCheckLimits();

    if(check_limit) {
      setLoadingData({button:true, grid:true})
      api.post('/api/users/create', {
        BenzamatOfficeId: 0,
        Firstname: data.Firstname,
        Lastname: data.Lastname,
        Middlename: data.Middlename,
        CompanyId: selectedCompany.value,
        Controllers: data.Controllers,
        IsExistDataAuth: data.IsExistDataAuth.value,
        AuthAuto: data.AuthTransport.value,
        Login: data.Login,
        Password: data.Password,
        PasswordController: data.PasswordController,
        LimitDay: limitDay,
        LimitMonth: limitMonth,
        BalanceAbsolute: data.AbsolutLimit != null ? data.AbsolutLimit : 0,
        RoleId: data.Role.id,
        RfidKey: data.NumberRfid,
        Contract: data.Contract,
        RfidIsMifare: selectedBenzaRfidType.value,
        TypeAuthId: selectedAuth.value,
        TypeLimitId: data.TypeLimit != null ? data.TypeLimit.value : 0,
        ServiceDate: data.ServiceDate != null ? data.ServiceDate.toISOString() : null
      })
      .then(function (response) {
          displayToast(response.data.message, response.data.isSuccess)
          setListUsers(response.data.listDatas)
          setLoadingData({button:false, grid:false})
          reset({
              Firstname:'',
              Lastname:'',
              Middlename:'',
              Companies:'',
              Login: '',
              Password: '',
              LimitDay:0,
              LimitMonth:0,
              CompanyId:'',
              IsExistDataAuth:''
          })
      })
      .catch(function (error) {
        console.log(error)
        let message = "";
        /*error.response.data.errors.map((item) => {
           message += item.errorMessage + "\n";
        })*/
        displayToast(error.response.data.errors[0].errorMessage, error.response.data.isSuccess)
        setLoadingData({button:false, grid:false})
      });
    } else {
      setLoadingData({button: false})
    }
  }
  
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.firstname+' '+row.lastname,
        accessorKey: 'name',
        header: translated.Fullname,
      },
      {
        accessorFn: (row) => row.companyName,
        accessorKey: 'company',
        header: translated.Company,
      },
      {
        accessorFn: (row) => row.rfidKey,
        accessorKey: 'rfidKey',
        header: "RFID",
      },
      {
        accessorFn: (row) => row.role.name,
        accessorKey: 'role',
        header: translated.Role,
      },
      {
        accessorFn: (row) => row.isExistDataAuth 
          ? translated.AccessActive
          : translated.AccessNotActive,
        accessorKey: 'Auth',
        header: translated.AccessSystem,
      },
      {
        accessorFn: (row) => 
        <>
          <Button onClick={() => router.push(`/user/${row.id}`)} className="btn btn-light btn-sm">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.125 10H16.875" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
          </Button> 

          <Button onClick={() => onDelete(row.id)} className="btn btn-danger btn-sm mx-3">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M4 7l16 0"></path>
                <path d="M10 11l0 6"></path>
                <path d="M14 11l0 6"></path>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
              </svg>
          </Button> 
        </>,
        accessorKey: 'button',
        header: translated.Actions,
      },
    ],
    [],
  );

  return (
    isAuth ?
    <>
    {
      isLoading
      ? <Spinner animation="border" variant="info" />
      :
      <>
        <div className="d-sm-flex align-items-center justify-content-between">
            <h2 className="mb-sm-0 fw-semibold  font-size-18 benzamat-font-bold text-black">
                {translated.Title}
                <span className="text-secondary benzamat-font-bold font-size-20"> {listUsers.length}</span>
            </h2>
            {
              permission.create || permission.fullAccess
              ?
              <div className="page-title-right">
                <DropdownButton title={translated.ActionsWithUser}>
                  <Dropdown.Item onClick={() => handleShow()}>{translated.AddUser}</Dropdown.Item>
                  <Dropdown.Item onClick={() => setUploadFormShow(true)}>{translated.UploadFromExcel}</Dropdown.Item>
                </DropdownButton>
              </div>
              : null
            }           
        </div>

        <div className="row">
        <div className="col-xs-12 col-lg-12 col-md-12 ">         
            <div className="mt-4">
            {
              permission.read || permission.fullAccess
              ?
              <MaterialReactTable 
                columns={columns} 
                data={listUsers}
                localization={MRT_Localization_EN}
                initialState={{ showColumnFilters: true }}
                enableTopToolbar={false}
                enableRowVirtualization
                muiTablePaperProps={{
                  elevation: 1,
                }}
                muiTableHeadCellProps={{
                  //simple styling with the `sx` prop, works just like a style prop in this example
                  sx: {
                    fontWeight: 'bold',
                    color:"#000",
                    backgroundColor:'#F3F3F3',
                    fontFamily: 'BenzamatRegular',
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    color:"#000",
                    fontFamily: 'BenzamatRegular',
                  },
                }}
              />
              : 
              <Alert variant="danger">
                {translated.PermissionDenied}
              </Alert>
            }
            </div>
        </div>
        </div>

        <UploadUsers setListUsers={setListUsers} setUploadFormShow={setUploadFormShow} uploadFormShow={uploadFormShow}  />

        <Modal backdrop="static" keyboard={false} show={showDeleteUser} onHide={() => setShowDeleteUser(false)}>
          <Modal.Header closeButton>
            <Modal.Title className="text-black">{translated.HeaderDelete}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="text-black text-center col-xs-12 col-lg-12 col-md-12">
                <h4>{translated.TextDelete}</h4>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => deleteUser()} className="btn btn-success">{translated.Submit}</Button>
            <Button onClick={() => setShowDeleteUser(false)} className="btn btn-danger">{translated.Cancel}</Button>
          </Modal.Footer>
        </Modal>

        <Offcanvas placement="end" show={showFormAdd} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
              {translated.AddUserTitle}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <div className="dropdown-divider"></div>

          <Offcanvas.Body>  
              <Form onSubmit={handleSubmit(onSubmit)}>

              <Form.Group className="mb-3">
                  <Form.Label className="text-black">{translated.Lastname} <sup className="text-danger">*</sup></Form.Label>
                  <Form.Control 
                      {...register("Lastname", {
                          minLength : {
                            value: 1,
                            message: translatedForm.MinLengthString(1)
                          },
                          maxLength : {
                            value: 50,
                            message: translatedForm.MaxLenghtString(50)
                          },
                          required: translatedForm.Required
                      })}
                      type="text" maxLength="50" isInvalid={errors.Lastname} 
                      placeholder={translatedForm.EnterValue(50)} className="form-control"  />
                      {errors.Lastname && 
                      <span className="text-danger font-size-13">{errors.Lastname.message}</span>
                      }
              </Form.Group>
            
              <Form.Group className="mb-3">
                  <Form.Label className="text-black">{translated.Firstname} <sup className="text-danger">*</sup></Form.Label>
                  <Form.Control 
                      {...register("Firstname", {
                          minLength : {
                          value: 1,
                          message: translatedForm.MinLengthString(1)
                          },
                          maxLength : {
                          value: 50,
                          message: translatedForm.MaxLenghtString(50)
                          },
                          required: translatedForm.Required
                      })}
                      type="text" maxLength="50" isInvalid={errors.Firstname} 
                      placeholder={translatedForm.EnterValue(50)} className="form-control"  />
                      {errors.Firstname && 
                      <span className="text-danger font-size-13">{errors.Firstname.message}</span>
                      }
              </Form.Group>

              <Form.Group className="mb-3">
                  <Form.Label className="text-black">{translated.Middlename}</Form.Label>
                  <Form.Control 
                      {...register("Middlename", {
                          minLength : {
                          value: 1,
                          message: translatedForm.MinLengthString(1)
                          },
                          maxLength : {
                          value: 100,
                          message: translatedForm.MaxLenghtString(100)
                          }
                      })}
                      type="text" maxLength="100" isInvalid={errors.Middlename}
                      placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                      {errors.Middlename && 
                      <span className="text-danger font-size-13">{errors.Middlename.message}</span>
                      }
              </Form.Group>

              <Form.Group className="mb-3">
                  <Form.Label className="text-black">{translated.Company} <sup className="text-danger">*</sup></Form.Label>        
                      <Controller
                          name="Companies"                                
                          control={control}
                          rules={{ required: translatedForm.Required }}
                          render={({ field }) => (
                          <Select 
                              value={selectedCompany}                             
                              isSearchable
                              options={listCompanies}                        
                              placeholder={translatedForm.SelectFromList}
                              classNamePrefix="select"
                              error={Boolean(errors.Company)}
                              onChange={(item) => {
                                setSelectedCompany({label:item.name, value:item.id})
                                setValue("Companies", selectedCompany)
                                //getStationsCompany(item.id)
                              }}
                          />
                          )}
                      />
                  {errors.Company && 
                      <span className="text-danger font-size-13">{errors.Company.message}</span>
                  }   
              </Form.Group>

              <Form.Group className="mb-3">
                  <Form.Label className="text-black">{translated.Role} <sup className="text-danger">*</sup></Form.Label>        
                      <Controller
                          name="Role"                                
                          control={control}
                          rules={{ required: translatedForm.Required }}
                          render={({ field: {value} }) => (
                          <Select                              
                              isSearchable
                              value={value}
                              options={listRoles}                        
                              placeholder={translatedForm.SelectFromList}
                              classNamePrefix="select"
                              error={Boolean(errors.Role)}
                              onChange={(item) => {
                                setValue("Role", item)
                                if(item.id == 5) {
                                  setRoleIsDriver(true)
                                } else {
                                  setRoleIsDriver(false)
                                }
                              }}
                          />
                          )}
                      />
                  {errors.Role && 
                      <span className="text-danger font-size-13">{errors.Role.message}</span>
                  }   
              </Form.Group>

              <>

                <Form.Group className="mb-3">
                <Form.Label className="text-black">{translated.TypeAuth} <sup className="text-danger">*</sup></Form.Label>
                  <Controller
                      name="TypeAuth"                                
                      control={control}
                      rules={{ required: translatedForm.Required }}
                      render={({ field: value }) => (
                      <Select
                        onChange={(item) => {
                          if(item.id == 1) {
                            setSelectedTypeAuth({rfid:true, password:false, rfid_password:false})
                          } else if (item.id == 2) {
                            setSelectedTypeAuth({rfid:false, password:true, rfid_password:false})
                          } else {
                            setSelectedTypeAuth({rfid:false, password:false, rfid_password:true})
                          }
                          setSelectedAuth({label: item.label, value:item.value})
                          setValue("TypeAuth", selectedAuth)
                        }}
                        value={selectedAuth}
                        options={listTypeAuth}                        
                        placeholder={translatedForm.SelectFromList}
                        classNamePrefix="select"
                      />
                      )}
                  />
                  {errors.TypeAuth && 
                      <span className="text-danger font-size-13">{errors.TypeAuth.message}</span>
                  }
                </Form.Group>

                {
                  selectedTypeAuth.password || selectedTypeAuth.rfid_password
                  ?
                  <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translated.Password}</Form.Label>
                    <Form.Control 
                      {...register("PasswordController", {
                        minLength : {
                          value: 5,
                          message: translatedForm.MinLengthString(5)
                        },
                        maxLength : {
                          value: 10,
                          message: translatedForm.MaxLenghtString(10)
                        },
                      })}
                      type="number" maxLength="10" isInvalid={errors.Password}
                      placeholder={translatedForm.EnterValue(10)} className="form-control"  />
                      {errors.Password && 
                        <span className="text-danger font-size-13">{errors.Password.message}</span>
                      }
                  </Form.Group>
                  : 
                  null
                }

                {
                  selectedTypeAuth.rfid || selectedTypeAuth.rfid_password
                  ?
                  <>

                  <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.BenzaRfidType} </Form.Label>
                      <Controller
                          name="BenzaRfidType"                                
                          control={control}
                          render={({ field: value }) => (
                          <Select
                            onChange={(item) => {
                              setSelectedBenzaRfidType({
                                label:item.label,
                                value:item.value
                              })
                            }}
                            value={selectedBenzaRfidType}
                            options={listBenzaRfidType}                        
                            placeholder={translatedForm.SelectFromList}
                            classNamePrefix="select"
                          />
                          )}
                      />
                      {errors.BenzaRfidType && 
                          <span className="text-danger font-size-13">{errors.BenzaRfidType.message}</span>
                      }
                  </Form.Group>

                  {/*<Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translated.NumberRfid}</Form.Label>
                    <Form.Control 
                      {...register("NumberRfid", {
                        maxLength : {
                          value: 15,
                          message: translatedForm.MaxLenghtString(15)
                        },
                        minLength : {
                          value: 5,
                          message: translatedForm.MinLengthString(5)
                        },
                      })}
                      type="text" maxLength="15" isInvalid={errors.NumberRfid}
                      placeholder={translatedForm.EnterValue(15)} className="form-control"  />
                      {errors.NumberRfid && 
                        <span className="text-danger font-size-13">{errors.NumberRfid.message}</span>
                      }
                  </Form.Group>*/}

                  <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translated.NumberRfid} <sup className="text-danger">*</sup></Form.Label>
                    <Controller
                        name="NumberRfid"                                
                        control={control}
                        rules={{ required: translatedForm.Required }}
                        render={({ field: {value} }) => (
                            <InputMask 
                                mask="**************"
                                className="form-control"
                                type="text"
                                formatChars={formatChars}
                                value={value}
                                placeholder={translatedForm.EnterValue(14)}
                                onChange={(item) => {
                                  setValue("NumberRfid", item.target.value)
                                }}
                            />
                        )}
                    />
                    {errors.NumberRfid && 
                        <span className="text-danger font-size-13">{errors.NumberRfid.message}</span>
                    }
                  </Form.Group>

                  <Button type="submit" onClick={() => getLastCard()} className="mb-2" variant="primary" disabled={rfidBtnLoad}>
                    {rfidBtnLoad && (
                        <Spinner animation="border" variant="light" 
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: "5px" }}
                        />
                    )}
                    {rfidBtnLoad && <span>{translated.ButtonRfid}</span>}
                    {!rfidBtnLoad && <span>{translated.ButtonRfid}</span>}
                  </Button>
                  </>
                  :
                  null
                }

                <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.AuthTransport}<sup className="text-danger">*</sup></Form.Label>        
                        <Controller
                            name="AuthTransport"                                
                            control={control}
                            rules={{ required: translatedForm.Required}}
                            render={({ field }) => (
                            <Select 
                            {...field}                               
                                isSearchable
                                options={listAuthAuto}                    
                                placeholder={translatedForm.SelectFromList}
                                classNamePrefix="select"
                                error={Boolean(errors.AuthTransport)}
                            />
                            )}
                        />
                    {errors.AuthTransport && 
                        <span className="text-danger font-size-13">{errors.AuthTransport.message}</span>
                    }   
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="text-black">{translated.TypeLimit} <sup className="text-danger">*</sup></Form.Label>
                    <Controller
                        name="TypeLimit"                                
                        control={control}
                        rules={{ required: translatedForm.Required }}
                        render={({ field: {value} }) => (
                        <Select
                          onChange={(item) => {
                            setSelectedTypeLimit(item)
                            setValue("TypeLimit", {label:item.label, value:item.value})
                          }}
                          value={value}
                          options={listTypeLimit}                        
                          placeholder={translatedForm.SelectFromList}
                          classNamePrefix="select"
                        />
                      )}
                    />
                    {errors.TypeLimit && 
                        <span className="text-danger font-size-13">{errors.TypeLimit.message}</span>
                    }
                  </Form.Group>

                  {
                    selectedTypeLimit.id == 5
                    ?
                    <>
                      <Form.Group className="mb-3">
                      <Form.Label className="text-black">{translated.LimitMonth}</Form.Label>
                    
                        <Controller
                            name="LimitMonth"                                
                            control={control}
                            render={({ field }) => (
                            <CurrencyInput
                                placeholder="Введите литры"
                                defaultValue={0}
                                decimalsLimit={2}
                                className="form-control"
                                onValueChange={(value) => setLimitMonth(value)}
                            />
                            )}
                        />
                        {errors.LimitMonth && 
                            <span className="text-danger font-size-13">{errors.LimitMonth.message}</span>
                        }   

                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label className="text-black">{translated.LimitDay}</Form.Label>
                      
                          <Controller
                              name="LimitDay"                           
                              control={control}
                              render={({ field }) => (
                              <CurrencyInput
                                  placeholder="Введите литры"
                                  defaultValue={0}
                                  decimalsLimit={2}
                                  className="form-control"
                                  onValueChange={(value) => setLimitDay(value)}
                              />
                              )}
                          />
                          {errors.LimitDay && 
                              <span className="text-danger font-size-13">{errors.LimitDay.message}</span>
                          }   

                      </Form.Group>
                    </>
                    : selectedTypeLimit.id == 3
                      ? 
                      <Form.Group className="mb-3">
                      <Form.Label className="text-black">{translated.LimitAbsolute}</Form.Label>
                    
                        <Controller
                            name="AbsolutLimit"                                
                            control={control}
                            render={({ field: {value} }) => (
                            <CurrencyInput
                                placeholder="Введите литры"
                                defaultValue={0}
                                decimalsLimit={2}
                                value={value}
                                className="form-control"
                                onValueChange={(value) => setValue("AbsolutLimit", value)}
                            />
                            )}
                        />
                        {errors.AbsolutLimit && 
                            <span className="text-danger font-size-13">{errors.AbsolutLimit.message}</span>
                        }   

                      </Form.Group>
                      : null
                  }

              </>
              
              <Form.Group className="mb-3">
              <Form.Label className="text-black">{translated.AccessSystem} <sup className="text-danger">*</sup></Form.Label>        
                  <Controller
                      name="IsExistDataAuth"                                
                      control={control}
                      rules={{ required: translatedForm.Required }}
                      render={({ field }) => (
                      <Select 
                      {...field}
                          options={listExistDataAuth}                        
                          placeholder={translatedForm.SelectFromList}
                          classNamePrefix="select"
                          error={Boolean(errors.IsExistDataAuth)}
                          onChange={(item) => {
                              showFormAuth(item)
                              setValue("IsExistDataAuth", item)
                          }}
                      />
                      )}
                  />
                  {errors.IsExistDataAuth && 
                      <span className="text-danger font-size-13">{errors.IsExistDataAuth.message}</span>
                  }   

              </Form.Group>

              {
                  showDataAuthForm
                  ?
                  <>
                      <Form.Group className="mb-3">
                          <Form.Label className="text-black">{translated.Login}</Form.Label>
                          <Form.Control 
                              {...register("Login", {
                                  minLength : {
                                  value: 5,
                                  message: translatedForm.MinLengthString(5)
                                  },
                                  maxLength : {
                                  value: 150,
                                  message: translatedForm.MaxLenghtString(150)
                                  }
                              })}
                              type="text" maxLength="150" isInvalid={errors.Login}
                              placeholder={translatedForm.EnterValue(150)} className="form-control"  />
                              {errors.Login && 
                              <span className="text-danger font-size-13">{errors.Login.message}</span>
                              }
                      </Form.Group>

                      <Form.Group className="mb-3">
                      <Form.Label className="text-black">{translated.Password}</Form.Label>
                      <Form.Control 
                          {...register("Password", {
                              minLength : {
                              value: 5,
                              message: translatedForm.MinLengthString(5)
                              },
                              maxLength : {
                              value: 20,
                              message: translatedForm.MaxLenghtString(20)
                              }
                          })}
                          type="text" maxLength="20" isInvalid={errors.Password}
                          placeholder={translatedForm.EnterValue(20)} className="form-control"  />
                          {errors.Password && 
                          <span className="text-danger font-size-13">{errors.Password.message}</span>
                          }
                      </Form.Group>
                  </>
                  :
                  ''
              }      

            <Form.Group className="mb-3">
              <Form.Label className="text-black">{translated.ServiceDate}</Form.Label>
              <Controller
                  name="ServiceDate"                                
                  control={control}
                  render={({ field:{value} }) => (
                  <Flatpickr
                      className='form-control'
                      options={{ 
                          time_24hr: true,  
                          enableTime: true,
                          dateFormat: "d.m.Y H:i",
                          locale: Russian
                      }}
                      value={value}
                      placeholder="Выберите дату"
                      onChange={([date]) => {
                          setValue("ServiceDate", date)
                      }}
                  />
                  )}
              />
            </Form.Group>

              <Form.Group className="mb-3">
                  <Form.Label className="text-black">{translated.Contract}</Form.Label>
                  <Form.Control 
                      {...register("Contract", {
                          minLength : {
                          value: 5,
                          message: translatedForm.MinLengthString(5)
                          },
                          maxLength : {
                          value: 100,
                          message: translatedForm.MaxLenghtString(100)
                          }
                      })}
                      type="text" maxLength="100" isInvalid={errors.Contract}
                       placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                  {errors.Contract && 
                    <span className="text-danger font-size-13">{errors.Contract.message}</span>
                  }
              </Form.Group>

              {
                permission.create || permission.fullAccess
                ?
                <div className="form-group mt-3">
                  <Button type="submit" variant="primary" disabled={loadingData.button}>
                    {loadingData.button && (
                        <Spinner animation="border" variant="light" 
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: "5px" }}
                        />
                    )}
                    {loadingData.button && <span>{translated.AddUser}</span>}
                    {!loadingData.button && <span>{translated.AddUser}</span>}
                  </Button>
                </div>
                : null
              }
            </Form>
            
          </Offcanvas.Body>
        </Offcanvas>

      </>
    }

  </>
   :
   <Redirect to='/login'/>
  );

}