import React, { useEffect, useState, Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import MetisMenu from '@metismenu/react';
import { Button, Tabs, Tab, Form, Spinner, Nav} from 'react-bootstrap';
import { Header } from './Header';
import axios from 'axios';
import './../../app.css';
import './../../style.css';
import './../../bootstrap.css';
import './../../icons.css';
import PermissionSvg from "../../img/SVG/permission.svg";
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { translations } from '../../lang/main';
import { CurrentSelectLanguage } from '../../helpers/language';
import api from '../../utils/axiosInterceptions';

export const Menu = () => {

    const [modules, setModules] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [roleAdminWebService, setRoleAdminWebService] = useState(false)
    const [menuIsCollapsed, setMenuIsCollapsed] = useState(false)
    const translated = useLittera(translations);
    const methods = useLitteraMethods();

    const getCurrentAuth = () => {
        setIsLoading(true)
        api.get('/api/users/current-auth', {
            withCrenditials: true
        })
        .then(function (response) {
            console.log(response)
            methods.setLocale(CurrentSelectLanguage);
            if(response.data.currentAuth.roleId == 1) {
                setRoleAdminWebService(true)
            }
            response.data.permission.map((item, index) => {
                if(item) {
                    let temp = renderMenu.find((i) => i.id === item.moduleId)
                    if(temp){
                        setModules(modules => [...modules, temp])
                    }
                }
            })
            setIsLoading(false)   
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        console.log(CurrentSelectLanguage)
        methods.setLocale(CurrentSelectLanguage);
        getCurrentAuth()   
    }, [])

    const hideMenu = () => {
        
        let textButtonHideMenu = document.getElementById("text-button-hide")

        if(document.body.className == "sidebar-enable vertical-collpsed") 
        {
            setMenuIsCollapsed(false)
            document.body.className = '';
            textButtonHideMenu.innerText = translated.hideMenu
        }
        else
        {
            setMenuIsCollapsed(true)
            document.body.className = "sidebar-enable vertical-collpsed";
            textButtonHideMenu.innerText = ''
        }
    }

    const renderMenu = [
        {
            id:1,
            label: translated.CompanyMenu,
            icon: <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.125 15.1875H16.875" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10.125 15.1875V2.8125C10.125 2.66332 10.0657 2.52024 9.96025 2.41475C9.85476 2.30926 9.71168 2.25 9.5625 2.25H2.8125C2.66332 2.25 2.52024 2.30926 2.41475 2.41475C2.30926 2.52024 2.25 2.66332 2.25 2.8125V15.1875" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.75 15.1875V7.3125C15.75 7.16332 15.6907 7.02024 15.5852 6.91475C15.4798 6.80926 15.3367 6.75 15.1875 6.75H10.125" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.5 5.0625H6.75" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.625 9.5625H7.875" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.5 12.375H6.75" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.375 12.375H13.5" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.375 9.5625H13.5" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>,
            link:"/companies",
        },
        {
            id:2,
            label: translated.stations,
            icon: <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 7.59375C10.5533 7.59375 11.8125 6.33455 11.8125 4.78125C11.8125 3.22795 10.5533 1.96875 9 1.96875C7.4467 1.96875 6.1875 3.22795 6.1875 4.78125C6.1875 6.33455 7.4467 7.59375 9 7.59375Z" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.2188 14.9062C14.7721 14.9062 16.0312 13.6471 16.0312 12.0938C16.0312 10.5404 14.7721 9.28125 13.2188 9.28125C11.6654 9.28125 10.4062 10.5404 10.4062 12.0938C10.4062 13.6471 11.6654 14.9062 13.2188 14.9062Z" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.78125 14.9062C6.33455 14.9062 7.59375 13.6471 7.59375 12.0938C7.59375 10.5404 6.33455 9.28125 4.78125 9.28125C3.22795 9.28125 1.96875 10.5404 1.96875 12.0938C1.96875 13.6471 3.22795 14.9062 4.78125 14.9062Z" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>,
            link:"/stations",
        },
        {
            id:3,
            label: translated.controllers,
            icon: <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.6875 12.9375V11.3294C1.6875 7.28789 4.93268 3.95153 8.97413 3.93755C9.9366 3.93414 10.8903 4.12077 11.7804 4.48673C12.6706 4.85269 13.4798 5.39078 14.1616 6.07014C14.8433 6.74951 15.3843 7.55678 15.7534 8.44565C16.1225 9.33453 16.3125 10.2875 16.3125 11.25V12.9375C16.3125 13.0867 16.2532 13.2298 16.1477 13.3352C16.0423 13.4407 15.8992 13.5 15.75 13.5H2.25C2.10082 13.5 1.95774 13.4407 1.85225 13.3352C1.74676 13.2298 1.6875 13.0867 1.6875 12.9375Z" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9 3.9375V6.1875" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1.9375 9.35742L4.11083 9.93976" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.064 9.35742L13.8906 9.93976" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.27344 13.4997L12.0818 7.2334" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>,
            link:"/controllers",
        },
        {
            id:5,
            label: translated.users,
            icon: <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 11.25C11.4853 11.25 13.5 9.23528 13.5 6.75C13.5 4.26472 11.4853 2.25 9 2.25C6.51472 2.25 4.5 4.26472 4.5 6.75C4.5 9.23528 6.51472 11.25 9 11.25Z" stroke="#E1E1E1" stroke-width="1.3" stroke-miterlimit="10" />
                <path d="M2.17969 15.1868C2.87125 13.9898 3.86565 12.9959 5.06298 12.3048C6.26031 11.6138 7.61841 11.25 9.00085 11.25C10.3833 11.25 11.7414 11.6138 12.9387 12.3049C14.136 12.996 15.1304 13.9899 15.8219 15.187" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>,
            link:"/users",
        },
        {
            id:6,
            label: translated.transports,
            icon: <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.8774 8.4797L16.8774 8.4797L14.3487 5.15215C14.0851 4.80521 13.6679 4.59827 13.2322 4.59827H11.2346V4.35611C11.2346 3.6951 10.6969 3.15742 10.0359 3.15742H2.02374C2.02374 3.15742 2.02374 3.15742 2.02374 3.15742C1.3627 3.15739 0.825024 3.69507 0.825024 4.35608V12.3161C0.825024 12.9771 1.3627 13.5148 2.02374 13.5148H3.03087C3.23714 14.2787 3.93561 14.8429 4.7642 14.8429C5.59279 14.8429 6.29123 14.2788 6.49749 13.5148H11.829C12.0353 14.2787 12.7338 14.8429 13.5623 14.8429C14.3909 14.8429 15.0894 14.2788 15.2956 13.5148H15.7145C16.5199 13.5148 17.175 12.8597 17.175 12.0543V9.36333C17.175 9.04604 17.0694 8.73243 16.8774 8.4797ZM13.4735 5.81721L13.4735 5.81721L14.9701 7.78651H11.2346V5.69749H13.2322C13.3262 5.69749 13.4167 5.74238 13.4735 5.81721ZM16.0758 11.9793H16.0758V12.0543C16.0758 12.2534 15.9137 12.4156 15.7146 12.4156H15.2427C14.9866 11.7365 14.3304 11.2517 13.5624 11.2517C12.7943 11.2517 12.1381 11.7365 11.882 12.4156H11.2346V10.7997H11.9241C12.2276 10.7997 12.4737 10.5537 12.4737 10.2501C12.4737 9.94659 12.2276 9.70051 11.9241 9.70051H11.2346V8.8857H15.8054L16.0022 9.14465C16.0022 9.14466 16.0022 9.14466 16.0022 9.14467C16.0496 9.20715 16.0758 9.28497 16.0758 9.36333V11.9793ZM3.08384 12.4156H2.02374C1.96894 12.4156 1.92424 12.3709 1.92424 12.3161V4.35608C1.92424 4.30132 1.96893 4.25661 2.02374 4.25661H10.0359C10.0907 4.25661 10.1354 4.30132 10.1354 4.35608V12.4156H6.44453C6.18843 11.7365 5.53226 11.2517 4.7642 11.2517C3.9961 11.2517 3.33994 11.7365 3.08384 12.4156ZM4.7642 13.7436C4.38027 13.7436 4.0678 13.4312 4.0678 13.0472C4.0678 12.6634 4.38027 12.3509 4.7642 12.3509C5.14809 12.3509 5.46057 12.6634 5.46057 13.0472C5.46057 13.4312 5.14809 13.7436 4.7642 13.7436ZM13.5623 13.7436C13.1784 13.7436 12.8659 13.4312 12.8659 13.0472C12.8659 12.6634 13.1784 12.3509 13.5623 12.3509C13.9462 12.3509 14.2587 12.6634 14.2587 13.0472C14.2587 13.4312 13.9463 13.7436 13.5623 13.7436Z" fill="#E1E1E1" stroke="#E1E1E1" stroke-width="0.15"/>
            </svg>,
            link:"/transports",
        },
        {
            id:7,
            label:translated.FuelIntake,
            icon: <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.0625 15.75C14.9945 15.75 15.75 14.9945 15.75 14.0625C15.75 13.1305 14.9945 12.375 14.0625 12.375C13.1305 12.375 12.375 13.1305 12.375 14.0625C12.375 14.9945 13.1305 15.75 14.0625 15.75Z" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.0625 3.9375H11.8125C12.4092 3.9375 12.9815 4.17455 13.4035 4.59651C13.8254 5.01847 14.0625 5.59076 14.0625 6.1875C14.0625 6.78424 13.8254 7.35653 13.4035 7.77849C12.9815 8.20045 12.4092 8.4375 11.8125 8.4375H5.0625C4.31658 8.4375 3.60121 8.73382 3.07376 9.26126C2.54632 9.78871 2.25 10.5041 2.25 11.25C2.25 11.9959 2.54632 12.7113 3.07376 13.2387C3.60121 13.7662 4.31658 14.0625 5.0625 14.0625H12.375" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>,
            link:"#",
            sub_links: [
                {
                    label:translated.FuelReceiptLog,
                    link:"/pourings"
                },
                {
                    label:translated.FuelIntakOnController,
                    link:"/pouring-controllers"
                },
            ]
        },
        {
            id:8,
            label:translated.analytics,
            icon: <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 9V2.25" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14.8456 5.625L3.1543 12.375" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2.36256 10.2342C2.07031 8.65258 2.35149 7.01859 3.15562 5.62567C3.95974 4.23274 5.23412 3.17212 6.7499 2.63428V7.70114L2.36256 10.2342Z" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.00039 2.25C10.183 2.25022 11.3448 2.56114 12.3695 3.15162C13.3941 3.74211 14.2456 4.59143 14.8388 5.61454C15.4319 6.63765 15.7458 7.79864 15.7491 8.98125C15.7524 10.1639 15.4449 11.3266 14.8575 12.353C14.27 13.3794 13.4232 14.2334 12.4019 14.8296C11.3805 15.4257 10.2205 15.7431 9.03789 15.7499C7.8553 15.7567 6.69168 15.4526 5.66355 14.8682C4.63542 14.2838 3.77888 13.4396 3.17969 12.42" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>,
            link:"#",
            sub_links: [
                {
                    label:translated.LastFillings,
                    link:"/reports/fillings"
                },
                {
                    label:translated.ReadingsLevelMeter,
                    link:"/reports/level-meter"
                },
                {
                    label:translated.ChangePrices,
                    link:"/reports/change-prices"
                },
                {
                    label:translated.CashDynamics,
                    link:"/reports/prices"
                },
                {
                    label:translated.LogAllMessage,
                    link:"/reports/messages"
                },
                {
                    label:translated.GsmOperationLog,
                    link:"/reports/gsm-connects"
                },
                {
                    label:translated.LogAllActionUsers,
                    link:"/reports/actions"
                },
                {
                    label:translated.FuelIntakController,
                    link:"/reports/gsm-pouring-controllers"
                },
                {
                    label:translated.TankLidReadings,
                    link:"/reports/event-hatch-open-close"
                },
                {
                    label:translated.FuelReleaseMass,
                    link:"/reports/gsm-mass"
                },
                {
                    label:translated.FuelReleaseController,
                    link:"/reports/gsm-controllers"
                },
                {
                    label:translated.FuelReleaseUser,
                    link:"/reports/gsm-users"
                },
                {
                    label:translated.FuleReleaseWithoutGroup,
                    link:"/reports/gsm-not-grouping"
                },
                {
                    label:translated.TotalReleaseFuelController,
                    link:"/reports/gsm-sum-controllers"
                },
                {
                    label:translated.TotalReleaseFuelUser,
                    link:"/reports/gsm-sum-users"
                },
                {
                    label:translated.TotalReleaseFuelTransport,
                    link:"/reports/gsm-sum-transports"
                },
                {
                    label:translated.ReportFuelIntake,
                    link:"/reports/fuel-intake"
                },

            ]
        }
    ]

    return (
        <>
        <Header />
        <div className="vertical-menu">
            <div data-simplebar="init" className="h-100">
                <div className="simplebar-wrapper" style={{margin: 0 + 'px'}}>
                    <div className="simplebar-height-auto-observer-wrapper">
                        <div className="simplebar-height-auto-observer"></div>
                    </div>
                    <div className="simplebar-mask">
                        <div className="simplebar-offset" style={{right: -17 + 'px', bottom: 0 + 'px'}}>
                            <div className="simplebar-content-wrapper" style={{height: 100 + '%', overflow: 'hidden scroll'}}>
                                <div className="simplebar-content" style={{padding: 0 + 'px'}}>
                                    <div id="sidebar-menu" className="mm-active">

                                        <button type="button" onClick={() => hideMenu()} id="button-hide-menu" className="btn d-none d-lg-block btn-light text-black bg-white mx-auto">
                                            <span className='label-icon me-2 align-middle' id="arrow-button-hide-menu">
                                                {
                                                    menuIsCollapsed
                                                    ? <svg  xmlns="http://www.w3.org/2000/svg"  width="22"  height="22"  viewBox="0 0 24 24"  fill="#c0c0c0"  class="icon icon-tabler icons-tabler-filled icon-tabler-circle-arrow-right"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 2l.324 .005a10 10 0 1 1 -.648 0l.324 -.005zm.613 5.21a1 1 0 0 0 -1.32 1.497l2.291 2.293h-5.584l-.117 .007a1 1 0 0 0 .117 1.993h5.584l-2.291 2.293l-.083 .094a1 1 0 0 0 1.497 1.32l4 -4l.073 -.082l.064 -.089l.062 -.113l.044 -.11l.03 -.112l.017 -.126l.003 -.075l-.007 -.118l-.029 -.148l-.035 -.105l-.054 -.113l-.071 -.111a1.008 1.008 0 0 0 -.097 -.112l-4 -4z" /></svg>
                                                    : <svg  xmlns="http://www.w3.org/2000/svg"  width="22"  height="22"  viewBox="0 0 24 24"  fill="#c0c0c0"  class="icon icon-tabler icons-tabler-filled icon-tabler-circle-arrow-left"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 2a10 10 0 0 1 .324 19.995l-.324 .005l-.324 -.005a10 10 0 0 1 .324 -19.995zm.707 5.293a1 1 0 0 0 -1.414 0l-4 4a1.048 1.048 0 0 0 -.083 .094l-.064 .092l-.052 .098l-.044 .11l-.03 .112l-.017 .126l-.003 .075l.004 .09l.007 .058l.025 .118l.035 .105l.054 .113l.043 .07l.071 .095l.054 .058l4 4l.094 .083a1 1 0 0 0 1.32 -1.497l-2.292 -2.293h5.585l.117 -.007a1 1 0 0 0 -.117 -1.993h-5.586l2.293 -2.293l.083 -.094a1 1 0 0 0 -.083 -1.32z" /></svg>
                                                }
                                            </span>
                                            <span id="text-button-hide" className="text-black">{translated.hideMenu}</span>
                                        </button>

                                        {
                                            !isLoading
                                            ?
                                            <MetisMenu>
                                            <li id="main-point" className="mt-3">                                                    
                                                <NavLink to="/dashboard" exact>
                                                <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.6871 14.6243V11.2492C10.6871 11.1 10.6278 10.957 10.5223 10.8515C10.4168 10.746 10.2738 10.6867 10.1246 10.6867H7.87459C7.7254 10.6867 7.58233 10.746 7.47684 10.8515C7.37135 10.957 7.31209 11.1 7.31209 11.2492V14.6243C7.31209 14.7735 7.25283 14.9165 7.14736 15.022C7.04189 15.1275 6.89883 15.1868 6.74966 15.1868L3.37507 15.1872C3.3012 15.1873 3.22804 15.1727 3.15979 15.1445C3.09154 15.1162 3.02952 15.0748 2.97728 15.0225C2.92504 14.9703 2.8836 14.9083 2.85532 14.84C2.82705 14.7718 2.8125 14.6986 2.8125 14.6247V8.12363C2.8125 8.04526 2.82888 7.96776 2.86058 7.89609C2.89228 7.82442 2.9386 7.76016 2.99659 7.70744L8.6212 2.59328C8.72474 2.49913 8.85965 2.44697 8.99959 2.44696C9.13954 2.44696 9.27445 2.49912 9.378 2.59325L15.0034 7.70744C15.0614 7.76016 15.1077 7.82442 15.1394 7.89609C15.1711 7.96777 15.1875 8.04527 15.1875 8.12365V14.6248C15.1875 14.6986 15.1729 14.7718 15.1447 14.84C15.1164 14.9083 15.075 14.9703 15.0227 15.0225C14.9705 15.0748 14.9085 15.1162 14.8402 15.1445C14.772 15.1727 14.6988 15.1873 14.6249 15.1873L11.2495 15.1868C11.1003 15.1868 10.9573 15.1275 10.8518 15.022C10.7463 14.9165 10.6871 14.7735 10.6871 14.6243V14.6243Z" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <span className="mx-2">{translated.dashboard}</span>
                                                </NavLink>
                                            </li>
                                        
                                        <li class="menu-title" style={{color:"#eaeaea", textTransform:"capitalize", fontSize:12+'px'}}>{translated.generalMenuFunc}</li>
                                        
                                        {
                                            modules.filter((i) => i.id != 8 && i.id != 7 && i.id != 26).map((item) => (
                                                <li key={item.id}>                                                        
                                                    <NavLink to={item.link}>
                                                        {item.icon}                                                                                                       
                                                        <span className="mx-2">{item.label}</span>
                                                    </NavLink>                                                    
                                                </li>
                                            ))                                                       
                                        }

                                        {/*<li>
                                            <Link to="#" className="has-arrow" activeClassName="active-element-menu">
                                                <svg  xmlns="http://www.w3.org/2000/svg"  width="18"  height="18"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-briefcase"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" /><path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2" /><path d="M12 12l0 .01" /><path d="M3 13a20 20 0 0 0 18 0" /></svg>
                                                <span class="mx-2">{translated.crm}</span>
                                            </Link>
                                            <ul style={{paddingLeft: 0 + 'rem', marginLeft:0 + 'rem'}}>
                                                {
                                                    renderMenu.find((i) => i.id == 26).sub_links.map((link) => (
                                                        <li>
                                                            <NavLink style={{paddingLeft:'53px'}} to={link.link}>
                                                                <span >{link.label}</span>
                                                            </NavLink>
                                                        </li>
                                                    ))
                                                }
                                            </ul> 
                                        </li>*/}

                                        <li>
                                            <Link to="#" className="has-arrow" activeClassName="active-element-menu">
                                                <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.0625 15.75C14.9945 15.75 15.75 14.9945 15.75 14.0625C15.75 13.1305 14.9945 12.375 14.0625 12.375C13.1305 12.375 12.375 13.1305 12.375 14.0625C12.375 14.9945 13.1305 15.75 14.0625 15.75Z" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M5.0625 3.9375H11.8125C12.4092 3.9375 12.9815 4.17455 13.4035 4.59651C13.8254 5.01847 14.0625 5.59076 14.0625 6.1875C14.0625 6.78424 13.8254 7.35653 13.4035 7.77849C12.9815 8.20045 12.4092 8.4375 11.8125 8.4375H5.0625C4.31658 8.4375 3.60121 8.73382 3.07376 9.26126C2.54632 9.78871 2.25 10.5041 2.25 11.25C2.25 11.9959 2.54632 12.7113 3.07376 13.2387C3.60121 13.7662 4.31658 14.0625 5.0625 14.0625H12.375" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                                <span class="mx-2">{translated.FuelIntake}</span>
                                            </Link>
                                            <ul style={{paddingLeft: 0 + 'rem', marginLeft:0 + 'rem'}}>
                                                {
                                                    renderMenu.find((i) => i.id == 7).sub_links.map((link) => (
                                                        <li>
                                                            <NavLink style={{paddingLeft:'53px'}} to={link.link}>
                                                                <span >{link.label}</span>
                                                            </NavLink>
                                                        </li>
                                                    ))
                                                }
                                            </ul> 
                                        </li>                                        
                                            
                                        <li>
                                            <Link to="#" className="has-arrow" activeClassName="active-element-menu">
                                                <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 9V2.25" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14.8456 5.625L3.1543 12.375" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M2.36256 10.2342C2.07031 8.65258 2.35149 7.01859 3.15562 5.62567C3.95974 4.23274 5.23412 3.17212 6.7499 2.63428V7.70114L2.36256 10.2342Z" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9.00039 2.25C10.183 2.25022 11.3448 2.56114 12.3695 3.15162C13.3941 3.74211 14.2456 4.59143 14.8388 5.61454C15.4319 6.63765 15.7458 7.79864 15.7491 8.98125C15.7524 10.1639 15.4449 11.3266 14.8575 12.353C14.27 13.3794 13.4232 14.2334 12.4019 14.8296C11.3805 15.4257 10.2205 15.7431 9.03789 15.7499C7.8553 15.7567 6.69168 15.4526 5.66355 14.8682C4.63542 14.2838 3.77888 13.4396 3.17969 12.42" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>    
                                                <span class="mx-2">{translated.analytics}</span>
                                            </Link>
                                            <ul style={{paddingLeft: 0 + 'rem', marginLeft:0 + 'rem'}}>
                                                {
                                                    renderMenu.find((i) => i.id === 8).sub_links.map((link) => (
                                                        <li>
                                                            <NavLink style={{paddingLeft:'53px'}} to={link.link}>
                                                                <span>{link.label}</span>
                                                            </NavLink>
                                                        </li>
                                                    ))
                                                }
                                            </ul> 
                                        </li>

                                        {
                                            CurrentSelectLanguage == "ru_RU"
                                            ?
                                            <>
                                                <li>
                                                    <a href="https://pay.benza.online" target="_blank" activeClassName="active-element-menu">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-currency-rubel" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFF" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                            <path d="M8 19v-14h6a3 3 0 0 1 0 6h-8" />
                                                            <path d="M14 15h-8" />
                                                        </svg>
                                                        <span class="mx-2">benzamatPay</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://benzamat.com/help/benzamat_pro_monual" target="_blank" activeClassName="active-element-menu">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-help">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                                            <path d="M12 17l0 .01" />
                                                            <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4" />
                                                        </svg>
                                                        <span class="mx-2">База знаний</span>
                                                    </a>
                                                </li>
                                            </>
                                            : null
                                        }
                                            

                                            {
                                                roleAdminWebService
                                                ?
                                                <>  
                                                    <li class="menu-title" style={{color:"#eaeaea", textTransform:"capitalize", fontSize:12+'px'}}>{translated.AdminMenuFunc}</li>
                                                    <li>
                                                        <NavLink to="/roles" exact>
                                                            <img src={PermissionSvg} />                                                       
                                                            <span className="mx-2">{translated.systemRole}</span>
                                                        </NavLink>
                                                    </li> 
                                                </>                                                   
                                                : null
                                            }

                                            {/*
                                                roleAdminWebService
                                                ?
                                                <>
                                                    <li>
                                                        <NavLink to="/roles" exact>
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-flame" width="21" height="21" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M12 12c2 -2.96 0 -7 -1 -8c0 3.038 -1.773 4.741 -3 6c-1.226 1.26 -2 3.24 -2 5a6 6 0 1 0 12 0c0 -1.532 -1.056 -3.94 -2 -5c-1.786 3 -2.791 3 -4 2z"></path>
                                                            </svg>                                                      
                                                            <span className="mx-2">{translated.logs}</span>
                                                        </NavLink>
                                                    </li> 
                                                </>                                                   
                                                : null
                                        */}


                                            {
                                                roleAdminWebService
                                                ? 
                                                <li>
                                                    <Link to="#" className="has-arrow">
                                                        <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.375 2.25V9L10.1245 7.3125L7.875 9V2.25" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M3.375 15.1875C3.375 14.7399 3.55279 14.3107 3.86926 13.9943C4.18572 13.6778 4.61495 13.5 5.0625 13.5H14.625V2.25H5.0625C4.61495 2.25 4.18572 2.42779 3.86926 2.74426C3.55279 3.06072 3.375 3.48995 3.375 3.9375V15.1875Z" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M3.375 15.1875V15.75H13.5" stroke="#E1E1E1" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>   
                                                        <span class="mx-2">{translated.dictionaries}</span>
                                                    </Link>
                                                    <ul style={{paddingLeft: 0 + 'rem', marginLeft:0 + 'rem'}}>
                                                        <li>
                                                            <NavLink style={{paddingLeft:'53px'}} to='/fuels'>
                                                                <span  className="mx-2">{translated.fuels}</span>
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </li>
                                                : null
                                            }
                                                                                                                   
                                            </MetisMenu>
                                            :
                                            <div className="d-flex justify-content-center">
                                                <Spinner size="sm" className="mt-4" animation="border" variant="light" />
                                            </div>  
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="simplebar-placeholder" style={{width: 'auto', height: 1910 + 'px'}}></div>
                </div>
                <div className="simplebar-track simplebar-horizontal" style={{visibility: 'hidden'}}>
                    <div className="simplebar-scrollbar" style={{transform: 'translate3d(0px, 0px, 0px)', display: 'none'}}>
                    </div>
                </div>
                <div className="simplebar-track simplebar-vertical" style={{visibility: 'visible'}}>
                    <div className="simplebar-scrollbar"
                        style={{height: 343 + 'px', transform: 'translate3d(0px, 289px, 0px)', display: 'block'}}></div>
                </div>
            </div>
        </div>
        
        </>
    );
}
