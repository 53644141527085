import React from "react";
import { useLittera } from "@assembless/react-littera";

export const TranslateSection = {

    Name:{
        en_US: "Name",
        ru_RU: "Название"
    },
    Fuel:{
        en_US: "Fuel",
        ru_RU: "Топливо"
    },
    AllVolume:{
        en_US: "All volume",
        ru_RU: "Полный объем"
    },
    CurrentVolume:{
        en_US: "Volume",
        ru_RU: "Текущий объем"
    },
    Occupancy:{
        en_US: "Occupancу",
        ru_RU: "Заполненность"
    },
    Actions:{
        en_US: "Actions",
        ru_RU: "Действия"
    },
    TRK:{
        en_US: "DU",
        ru_RU: "ТРК"
    },
    Dispenser:{
        en_US: "Dispenser",
        ru_RU: "Рукав"
    },
    AddSection:{
        en_US: "Add section",
        ru_RU: "Добавление секции"
    },
    Edit:{
        en_US: "Edit section",
        ru_RU: "Изменение данных"
    },
    Empty:{
        en_US: "Empty",
        ru_RU: "Не указано"
    },
    Height:{
        en_US: "Height",
        ru_RU: "Высота, см"
    },
    MinLevel:{
        en_US: "Min. Level",
        ru_RU: "Мин. уровень, см"
    },
    MaxLevel:{
        en_US: "Max. Level",
        ru_RU: "Макс. уровень, см"
    },
    MinVolume:{
        en_US: "Min. Level",
        ru_RU: "Мин. объем, л"
    },
    MaxVolume:{
        en_US: "Max. Volume",
        ru_RU: "Макс. объем, л"
    },
    FullVolume:{
        en_US: "Full volume",
        ru_RU: "Полный объем, л"
    },
    SaveChanges:{
        en_US: "Save changes",
        ru_RU: "Сохранить"
    },
    LevelMeter:{
        en_US: "Level meter",
        ru_RU: "Уровнемер"
    },
};
